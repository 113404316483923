import { getData, postData } from './wrapper';
import NEW_API_ENDPOINT from '@CONSTANTs/new_api_endpoint';

export function getFBReviews(pageID) {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/review_testing/get-fb-reviews?pageId=${pageID}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getFBComments(pageID, reviewID) {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/review_testing/get-fb-comments?pageId=${pageID}&reviewId=${reviewID}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function FBLogin() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/review_testing/construct-fb-auth-url?authType=facebook`)
      .then((data) => {
        resolve(data);
        window.open(
          'http://app.restaverse.com/apps/reviews_and_reputation/facebook?longTokenStatus=successful#_=_' ||
            (data && data.authUrl),
          // 'http://localhost:3000/apps/reviews_and_reputation/facebook?longTokenStatus=successful#_=_',
          'facebookLogin'`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=700,height=800,left=-1000,top=-1000`
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getFBPages() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/review_testing/get-fb-pages`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reviewReplyFB(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/reply-fb-review`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getAccount() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/review_testing/get-accounts`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getUserLocation(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/get-locations-for-user`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function inviteLocations(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review_testing/invite_locations/invite-locations`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getLocation(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review_testing/get_locations/get-locations`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getReviews(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review_testing/get_reviews/get-reviews`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function GoogleLogin() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/review_testing/construct-reviews-auth-uri`)
      .then((data) => {
        resolve(data);
        console.log(data);
        window.open(
          'http://localhost:3000/apps/reviews_and_reputation/google?success=true',
          'gmailLogin'
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getComments(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review/comments`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getMentionsUsers(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review/mentions-users`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getMentionNotifications(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review/mention-notifications`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateMentionStatus(payload) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/review/mentions/status`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fetchMetaPageIds() {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/messenger/pages`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function fetchMetaConversations(platform, pageId, cursor=null) {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/${platform}/pages/${pageId}/conversations${cursor ? `?cursor=${cursor}` : ''}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function fetchMetaConversation(platform, cid, pageId, cursor=null) {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/${platform}/pages/${pageId}/conversations/${cid}/messages${cursor ? `?cursor=${cursor}` : ''}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fetchMetaConversationMessage(platform, mid, pageId) {
  return new Promise((resolve, reject) => {
    getData(`${NEW_API_ENDPOINT}/api/${platform}/pages/${pageId}/messages/${mid}`)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function replyMetaConversation(platform, cid, payload, pageId) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/${platform}/pages/${pageId}/conversations/${cid}/messages`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function reactMetaConversation(platform, cid, mid, payload, pageId) {
  return new Promise((resolve, reject) => {
    postData(`${NEW_API_ENDPOINT}/api/${platform}/pages/${pageId}/conversations/${cid}/messages/${mid}/react`, payload)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}